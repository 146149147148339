import React from 'react';
import { Routes, Route } from 'react-router-dom';
import './App.css';
import { ApproveDocuments } from './components/approveDocuments/ApproveDocuments';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img
          src="../Hjallur_Merki_Hvitt2_Cropped.png"
          className="App-logo"
          alt="Hjallur"
        />
        <div className="main">
          <Routes>
            <Route path="/:shipmentId" element={<ApproveDocuments />} />
            <Route
              path="*"
              element={<p>Enginn skjalapakki hefur verið valinn.</p>}
            />
          </Routes>
        </div>
        <a
          className="App-link"
          href="https://lavaseafood.is"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src="../LavaWhite.png" className="App-logo" alt="Lava Seafood" />
        </a>
      </header>
    </div>
  );
}

export default App;
