import React, { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { isUUID, isInt } from 'validator';
import ReactLoading from 'react-loading';

let apiUrl = process.env.REACT_APP_APPROVE_URL;

if (!apiUrl) {
  apiUrl =
    'https://prod-32.westeurope.logic.azure.com:443/workflows/4a4dedc856f44d788cdd9f69940a8ee8/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=PWiXcMyZ3roQCBoqWa7nQKK4drfMioMQD4FLA5YPLHs';
}

export function ApproveDocuments() {
  const { shipmentId } = useParams();
  const [searchParams] = useSearchParams();
  const [versionNo] = useState(searchParams.get('version'));

  const [sending, setSending] = useState(false);
  const [loading, setLoading] = useState(false);
  const [approveSent, setApproveSent] = useState(false);
  const [error, setError] = useState('');
  const [lsno, setLsno] = useState('');

  async function sendApproval() {
    if (isUUID(shipmentId) && isInt(versionNo)) {
      setSending(true);
      setError('');
      const myHeaders = new Headers();
      myHeaders.append('Content-Type', 'application/json');

      const raw = JSON.stringify({
        shipmentId,
        versionNo: Number.parseInt(versionNo, 10),
        getLsNo: false,
      });

      const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow',
      };

      const result = await fetch(apiUrl, requestOptions);
      const response = await result.json();
      setSending(false);
      if (result.ok) {
        setApproveSent(true);
        setLsno(response.lsno);
      } else {
        setError(response.message ?? 'Úps, eitthvað fór úrskeiðis 🥴');
      }
    }
  }

  async function getLsNo() {
    if (isUUID(shipmentId) && isInt(versionNo)) {
      setLoading(true);
      setError('');
      const myHeaders = new Headers();
      myHeaders.append('Content-Type', 'application/json');

      const raw = JSON.stringify({
        shipmentId,
        versionNo: Number.parseInt(versionNo, 10),
        getLsNo: true,
      });

      const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow',
      };

      const result = await fetch(apiUrl, requestOptions);
      const response = await result.json();
      setLoading(false);
      if (result.ok) {
        setLsno(response.lsno);
      } else {
        setError(response.message ?? 'Úps, eitthvað fór úrskeiðis 🥴');
      }
    }
  }

  useEffect(() => {
    getLsNo();
  }, []);

  if (!isUUID(shipmentId) || !versionNo || !isInt(versionNo)) {
    return (
      <div>
        <img
          alt=""
          // eslint-disable-next-line max-len
          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAABmJLR0QA/wD/AP+gvaeTAAAD2UlEQVRoge2ZXYhVVRiG329GpcafZoxCMScrs7IiCorERIwgLzQv6sIaKFAKhroJwotoaPqxn6mcGZJEqdskwUApQgTBoi5C6kIaQRDKwgybzMzKnHy6ON90tjPn7LPWOmfOrpgXNpy91/eu86z1nb33t9aRJjWp/6aAh4C1RXPUJeA24C8/7iiaJ0mAAZ9Q1meAFc0VLaDLB3DcD4CuormiBLQB3zj8OmC9f/4OmF40X7CAFxz8C6DFj8/92vNF8wUJmA+ccehlmetLgPPAb8CCIhmDBOzwQbxboW27t71XBFuwgKWZWb+yQvsVwK8+mOVFMNaU3wcHHPLZnLhej/kSaG0mY5CAxxzw27wnE3Ax8LXHPtpMxpoCZgHfO1zNcgR40GN/ANqbwRgk4I3YtzfwsXten2i+IAELgT8o1VO3R/hudc+fwHUTyRgK9KHP7NsJ3nfc+8FEsMWArHSQU8CcCu3TgA4/plVon+NegJXNoR4PMRU45BAbqsT0UFZPlZgN3n4ImJrK05JqlPSEpOslHZE0WCWmtcrnrAYkHfa+Hq+DJ17AbGDYZ/K+nLjeTEZ6c+LWeMxJ4LIUptSMvCRptqR9ZrY7sY9/ZGa7JO2R1C6pOdUxcCNwDhgBbq4RG5QRj13sj+IR4JZYrpSMbJY0RdJbZnYwwV9RZjYkaatK99Lm0BdrkoAHfHZ/Ai4NiA/OiMd3ACc8/v4YtuCMABdJ6vPTHjMbjvmiEJnZSUnP+ekmoC3UG/PTekrSVZKGJG2L8MVqi6SDkjolPdnQnoF5wGlP+b0RvqifVsZ3t3vOAJ0hntCMvCJphqRdZrYnFChVZrZP0m5JbZJebEinwJ2Ulq9ngUWR3qSMuPcaSlX1eeCuWvG5GfFH4KAkk9RvZodjYOqRmY2WPiZpAEgvp4BHfDaPA5ck+JMz4v6ZwDH3P5wXW3WUwAyVShFJetrMTsWCSBqp8jlIZnZa0jN++rIzxQnY6DNxIDWtlEr90fVIUonOhbszG2PNVwO/+422rLZjYkV5l/IscG2M8X2fgXG7hUWJ8i7lzlDDitiXUU5fr2Zu9r7ajty+OinvKa8Y294yJrhVpRWbJPWZ2dF6vlxSdp2evIyVJGd5zU/fBKZUDQa6fcRHiSjYcvrrz2SkvwH9tTkbQHe1oGwJ3ZA/L4EFwD1+jNvUTuxzrTOeADoqBQx6wKf8y//rA/Y768DYhhsoLTOjdguLEuVdynPATdmGj3yE0buFRYnyLuXe0Qur/cIvwNyC+YIFXA787OyrDPhK0uKiwerUUIukhq+9C9CPRQNM6n+rvwGuu1J/19EGWAAAAABJRU5ErkJggg=="
        />
        <p>Ógildur hlekkur.</p>
      </div>
    );
  }

  if (error) {
    return (
      <div>
        <img
          alt=""
          // eslint-disable-next-line max-len
          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAABmJLR0QA/wD/AP+gvaeTAAAD2UlEQVRoge2ZXYhVVRiG329GpcafZoxCMScrs7IiCorERIwgLzQv6sIaKFAKhroJwotoaPqxn6mcGZJEqdskwUApQgTBoi5C6kIaQRDKwgybzMzKnHy6ON90tjPn7LPWOmfOrpgXNpy91/eu86z1nb33t9aRJjWp/6aAh4C1RXPUJeA24C8/7iiaJ0mAAZ9Q1meAFc0VLaDLB3DcD4CuormiBLQB3zj8OmC9f/4OmF40X7CAFxz8C6DFj8/92vNF8wUJmA+ccehlmetLgPPAb8CCIhmDBOzwQbxboW27t71XBFuwgKWZWb+yQvsVwK8+mOVFMNaU3wcHHPLZnLhej/kSaG0mY5CAxxzw27wnE3Ax8LXHPtpMxpoCZgHfO1zNcgR40GN/ANqbwRgk4I3YtzfwsXten2i+IAELgT8o1VO3R/hudc+fwHUTyRgK9KHP7NsJ3nfc+8FEsMWArHSQU8CcCu3TgA4/plVon+NegJXNoR4PMRU45BAbqsT0UFZPlZgN3n4ImJrK05JqlPSEpOslHZE0WCWmtcrnrAYkHfa+Hq+DJ17AbGDYZ/K+nLjeTEZ6c+LWeMxJ4LIUptSMvCRptqR9ZrY7sY9/ZGa7JO2R1C6pOdUxcCNwDhgBbq4RG5QRj13sj+IR4JZYrpSMbJY0RdJbZnYwwV9RZjYkaatK99Lm0BdrkoAHfHZ/Ai4NiA/OiMd3ACc8/v4YtuCMABdJ6vPTHjMbjvmiEJnZSUnP+ekmoC3UG/PTekrSVZKGJG2L8MVqi6SDkjolPdnQnoF5wGlP+b0RvqifVsZ3t3vOAJ0hntCMvCJphqRdZrYnFChVZrZP0m5JbZJebEinwJ2Ulq9ngUWR3qSMuPcaSlX1eeCuWvG5GfFH4KAkk9RvZodjYOqRmY2WPiZpAEgvp4BHfDaPA5ck+JMz4v6ZwDH3P5wXW3WUwAyVShFJetrMTsWCSBqp8jlIZnZa0jN++rIzxQnY6DNxIDWtlEr90fVIUonOhbszG2PNVwO/+422rLZjYkV5l/IscG2M8X2fgXG7hUWJ8i7lzlDDitiXUU5fr2Zu9r7ajty+OinvKa8Y294yJrhVpRWbJPWZ2dF6vlxSdp2evIyVJGd5zU/fBKZUDQa6fcRHiSjYcvrrz2SkvwH9tTkbQHe1oGwJ3ZA/L4EFwD1+jNvUTuxzrTOeADoqBQx6wKf8y//rA/Y768DYhhsoLTOjdguLEuVdynPATdmGj3yE0buFRYnyLuXe0Qur/cIvwNyC+YIFXA787OyrDPhK0uKiwerUUIukhq+9C9CPRQNM6n+rvwGuu1J/19EGWAAAAABJRU5ErkJggg=="
        />
        <p>{error}</p>
      </div>
    );
  }

  if (sending) {
    return (
      <div>
        <ReactLoading type="balls" color="#fff" />
        <p>Sendir samþykki á Lava Seafood...</p>
      </div>
    );
  }

  if (loading) {
    return (
      <div>
        <ReactLoading type="balls" color="#fff" />
      </div>
    );
  }

  if (approveSent) {
    return (
      <div>
        <img
          alt=""
          // eslint-disable-next-line max-len
          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAABmJLR0QA/wD/AP+gvaeTAAADN0lEQVRoge2aT4hVVRyAvzPogK9pU2PbNGGcdFFBGwkkB412uRKF2iQEQe3CGsuWuWwjLYK2iiKoJIE16YgWNC5qlSH0B6KBiHYhzkDzubjv6p3z7n3z7r/33sJvdTnn3XO+37333PN751x4xGgJTTamdoBZYAbYCnS6VXeBf4A7wC8hhLtN9lsLdYd6Qr2prroxq+oN9SP1mVGKH1CvqmsDSBexpn6r7h+m+G51sYZ0EdfUXW2KT6jzDvaYVGVV/UBtdGyiTqlftSgec1mdakp+Wr01RPmUJXW6rnxH/X4E8im33OBOTPSRnwDOA3tqXYV6vAicscqYUI+P8MrHHCvyzI1M3Q38CGwuHXk7rALPhxBuxxVFj9BnjI88wCRwKq+iJwD1FWBv20YVmFPn4sK8O/D+EGSqMh8XrBsD6nbg17h8jBDYEUL4PS2I78AbDE9+GXgNOAj8O+A5AThSWGuSEg+Dv9SZTL+fljj3epF8x3YTtZRYftJyqcqKuiU9P/sIzdL+q/Nv4EAI4U4qD5wlmXEHZZLkHx+wPoCZ3t82yjKwN4TwMzyQP0cyBsqyMz3YlCncWrIRgdM8HFj9Bv8ysC+68udIBnEVnkoPsgGUzb9PhxBe7wpdBT4nf15pWh7g8fSgMBstQwjhC+AtYC2qakM+H/Xdkm+T/9WjURtvdsuL3jYXS/ZRxDt5ARyu0FBREH+2KK96KC+AFyo2lhfEYy3Kqz6XF0DHZJKoQk8QLcrfMzORxR3WSSXW1LczbW1WLzRhHLGYdd4UxXAFeCk3uo0JwCn1CeAacAJ4tWJb/bgSd/oAdRvwW1w+RvRPp0MIf5BcvXFlISsP+RPZySHJVOGTuKAngBDCArAYl48BCyGEHq+iZZVdwE+Mz8pEuWWVbsr7cdtWJfgwT74vJsvpl1t4j5flklWX201m5+9GKL9k3WV2k+X1pRHI/6A+WUs+E8SU+uUQ5S+ZSQibCiKox6ye8A3CivqeTW8xRYE8a7Kz2DTfqLOtiecEMtfttO4269fqy0MTzwlkm8lmyHUHe7xWTLZp59Wn6/bf9KcGW0jWbHYC0zxc6fiP9Z8a3Guy30eMkvv22GWvClI3mgAAAABJRU5ErkJggg=="
        />
        <p>Takk fyrir að samþykkja skjöl {lsno}.</p>
        <p>Það má loka þessum glugga.</p>
      </div>
    );
  }

  return (
    <div>
      <button type="button" onClick={sendApproval}>
        Senda samþykki {lsno && `á ${lsno}`}
      </button>
    </div>
  );
}
